import { useEmblaCarousel } from 'embla-carousel/react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import tw, { css } from 'twin.macro'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'
import Image from './image'
import Text from './text'

const CardList = ({ cards, style }) => {
  const [viewportRef, embla] = useEmblaCarousel({ align: 'start' })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const onSelect = useCallback(() => {
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla])

  useEffect(() => {
    if (!embla) return
    embla.on('select', onSelect)
    onSelect()
  }, [embla, onSelect])

  const [activeItem, setActiveItem] = useState(0)
  const handleMouseOver = (_, i) => {
    setActiveItem(i)
  }

  return (
    <>
      <div css={tw`relative w-full overflow-hidden lg:hidden`} ref={viewportRef}>
        <div css={tw`flex flex-row w-full`}>
          {cards.map((card, i) => (
            <div
              key={i}
              css={[
                tw`flex flex-col flex-none w-5/6 pt-10 pb-16 mx-3 transition-all duration-300 ease-in-out px-7 bg-secondary-500`,
                selectedIndex === i && tw`text-white bg-primary-500`,
              ]}
            >
              <div css={tw`w-16 mb-8`}>
                <Image image={selectedIndex === i ? card.mobileImage : card.image} />
              </div>
              <div css={tw`w-full divide-y`}>
                <Heading content={card.title} headingType="h4" style={tw`pb-6 font-bold`} />
                <Text
                  content={card.descriptionNode}
                  style={css`
                    ${tw`pt-6`}
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0.0025em;
                  `}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div css={[tw`hidden lg:(flex flex-col flex-row gap-x-2 justify-center)`, style]}>
        {cards.map((card, i) => (
          <div
            key={i}
            css={[
              tw`flex flex-col px-5 py-10 transition-all duration-300 ease-in-out flex-equal bg-secondary-300 opacity-40`,
              activeItem === i &&
                css`
                  ${tw`text-white transform translate-y-4 opacity-100 bg-primary-500`}
                  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.4);
                `,
            ]}
            onMouseOver={(e) => handleMouseOver(e, i)}
            onFocus={(e) => handleMouseOver(e, i)}
          >
            <div css={tw`w-12 h-12 mb-9`}>
              <Image image={activeItem === i ? card.mobileImage : card.image} />
            </div>
            <Text
              textType="body-lg"
              content={card.title}
              style={tw`font-bold leading-6! lg:h-18 xxl:h-12`}
            />
            <div
              css={[
                tw`w-full h-px my-5 bg-primary-500 opacity-20`,
                activeItem === i && tw`bg-white`,
              ]}
            />
            <Text
              style={css`
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.0025em;
              `}
              content={card.descriptionNode}
            />
          </div>
        ))}
      </div>
    </>
  )
}

CardList.propTypes = {
  cards: PropTypes.array,
  style: StyleType,
}

export default CardList
